var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading, rounded: "sm", "no-center": "" } },
    [
      _vm._v(" Results "),
      _c("strong", [
        _vm._v(
          _vm._s((_vm.currentPage - 1) * _vm.perPage + 1) +
            " - " +
            _vm._s(_vm.currentPage * _vm.perPage)
        )
      ]),
      _vm._v(" of "),
      _c("strong", [_vm._v(_vm._s(_vm.totalRows))]),
      _vm._v(" items. "),
      _c(
        "b-table",
        {
          ref: "table",
          attrs: {
            striped: "",
            hover: "",
            items: _vm.itemProvider,
            "current-page": _vm.currentPage,
            "per-page": _vm.perPage,
            fields: _vm.fields
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function(_, name) {
                return {
                  key: name,
                  fn: function(slotData) {
                    return [_vm._t(name, null, null, slotData)]
                  }
                }
              }),
              {
                key: "cell(idx)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.currentPage - 1) * _vm.perPage + data.index + 1
                        ) +
                        " "
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        [
          _vm._l(_vm.$slots, function(_, name) {
            return _vm._t(name, null, { slot: name })
          })
        ],
        2
      ),
      _vm.totalRows > _vm.perPage
        ? _c("b-pagination", {
            staticClass: "mt-4",
            attrs: {
              "total-rows": _vm.totalRows,
              "per-page": _vm.perPage,
              limit: "11",
              "aria-controls": "my-table"
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("rest-table", {
    attrs: {
      endpoint: "task-attributes",
      fields: _vm.fields,
      filter: _vm.filter
    },
    scopedSlots: _vm._u([
      {
        key: "cell(Description)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "task-detail-overview",
                    params: { id: data.item.TaskAttributeID }
                  }
                }
              },
              [_vm._v(_vm._s(data.item.Description))]
            )
          ]
        }
      },
      {
        key: "cell(CompletionDateTimeFormatted)",
        fn: function(data) {
          return [
            data.item.CompletionDateTime
              ? [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("dateFormat")(data.item.CompletionDateTime))
                  )
                ]
              : data.item.MinimumCompletionDateTime
              ? [
                  _vm._v(
                    _vm._s(
                      _vm._f("dateFormat")(data.item.MinimumCompletionDateTime)
                    ) + " (*)"
                  )
                ]
              : data.item.CompletionMinimumIntervalFromParent
              ? [_vm._v(_vm._s(data.item.CompletionMinimumIntervalFromParent))]
              : [_vm._v("Not set")]
          ]
        }
      },
      {
        key: "cell(actions)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "task-detail-overview",
                    params: { id: data.item.TaskAttributeID }
                  }
                }
              },
              [_c("b-icon-pencil-square")],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
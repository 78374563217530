<template>
  <b-overlay :show="loading" rounded="sm" no-center>
    Results <strong>{{ ((currentPage - 1) * perPage) + 1 }} - {{ ((currentPage) * perPage) }}</strong> of <strong>{{ totalRows }}</strong> items.
 
    <b-table 
        striped
        hover
        ref="table"
        :items="itemProvider"
        :current-page="currentPage"
        :per-page="perPage"
        :fields="fields">

      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>

      <template v-slot:cell(idx)="data">
        {{ (( currentPage-1) * perPage) + data.index + 1}}
      </template>
    </b-table>

    <b-pagination
      v-if="totalRows > perPage"
      class="mt-4"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      limit="11"
      aria-controls="my-table"
    ></b-pagination>
  </b-overlay>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'RestTable',
    data () {
      return {
        currentFilter: null,
        cancelToken: null,
        loading: false,
        totalRows: 0,
        perPage: 20,
        currentPage: 1,
      }
    },
    props: {
      fields: Array,
      filter: Object,


      endpoint: String,
    },
    watch: {
      filter: {
        deep: true,
        handler: function () {
          if (JSON.stringify(this.currentFilter) == JSON.stringify(this.filter)) 
            return

          if (this.currentPage != 1) {
            this.currentPage = 1
          } else {
            this.$refs.table.refresh()
          }
        }
      }
    },
    methods: {
      itemProvider (ctx) {
        return new Promise((resolve) => {
          if (this.cancelToken) {
            this.cancelToken.cancel()
          }
          this.cancelToken = axios.CancelToken.source()

          this.currentFilter = {...this.filter}
          ctx['filter'] = this.filter

          this.loading = true
          
          this.$http
              .get(this.endpoint, { params: ctx, cancelToken: this.cancelToken.token })
              .then(response => {
                  this.totalRows = Number(response.headers['x-pagination-count'])
                  this.loading = false
                  
                  resolve(response.data)
              }).catch(function () {
                  resolve([])
              })
        })
      },
    },
  }
</script>

<style lang="scss">
  table td.link-icons {
    a {
      font-weight: normal;
    }

    font-size: 1.65rem;
    
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
</style>
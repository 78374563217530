<template lang="pug">

rest-table(endpoint='task-attributes' :fields='fields' :filter='filter')
  template(v-slot:cell(Description)='data')
    router-link(
      :to=`{ 
        name: 'task-detail-overview', 
        params: { id: data.item.TaskAttributeID } 
      }`
    ) {{ data.item.Description }}

  template(v-slot:cell(CompletionDateTimeFormatted)='data')

    template(v-if='data.item.CompletionDateTime') 
      | {{ data.item.CompletionDateTime|dateFormat }}
    template(v-else-if='data.item.MinimumCompletionDateTime')
      | {{ data.item.MinimumCompletionDateTime|dateFormat }} (*)
    template(v-else-if='data.item.CompletionMinimumIntervalFromParent')
      | {{ data.item.CompletionMinimumIntervalFromParent }}
    template(v-else) Not set

  template(v-slot:cell(actions)='data')
    router-link(:to="{ name: 'task-detail-overview', params: { id: data.item.TaskAttributeID } }")
      b-icon-pencil-square

</template>

<script>
  import RestTable from '@/components/RestTable.vue'

  export default {
    name: 'TaskList',
    components: {
      RestTable,
    },
    props: {
      filter: Object
    },
    data () {
      return {
        fields: [
          {
            key: 'TaskReference',
            label: 'Reference',
            sortable: false,
          },
          /*
          {
            key: 'CreationDateTimeFormatted',
            label: 'Created',
            sortable: false,
          },*/
          {
            key: 'CompletionDateTimeFormatted',
            label: 'Due',
            sortable: false,
          },
          {
            key: 'Status',
            label: 'Status',
            sortable: false,
          },
          {
            key: 'User.FullName',
            label: 'Owner',
            sortable: false,
          },
          {
            key: 'Description',
            label: 'Description',
            sortable: false,
          },
          /*
          {
            key: 'AttributeType.Description',
            label: 'Type',
            sortable: false,
          },*/

          {
            key: 'actions',
            label: 'Acties',
            class: 'link-icons',
          },
        ],
      }
    }
  }
</script>